<div class="card-content" [style.background]="shop?.unavailable ? '#F4F3F3' : '#FFFFFF'">
  <div *ngIf="shop.unavailable" class="card-row card-row-margin shop-unavailable oneX-body--secondary">
    Appointment available for<br>
    drivable vehicles only
  </div>
  <div class="card-row" id="shop-name">
    <div class="oneX-Hyperlink-block--primary index">
      {{index}}.
    </div>
    <div class="oneX-Hyperlink-block--primary title">
      {{shop.name | titlecase}}
    </div>
    <div *ngIf="shop?.distance" class="oneX-body--secondary miles">
      {{shop?.distance | number : '1.0-1' }} mi
    </div>
  </div>
  <app-shop-star-rating *ngIf="!searchFlow" id="shop-rating" class="card-row-margin" [location]="shop?.placeDetails"
    [isSidebarVisabile]="isSidebarVisabile" [areReviewsClickable]="false"></app-shop-star-rating>
  <div id="shop-address" class="card-row card-row-margin address oneX-body--secondary">
    {{shop.address}}, {{shop.city | titlecase}}, {{shop?.state}} {{shop.postalCode | slice:0:5}}
  </div>
  <div *ngIf="!shop.unavailable && !searchFlow" class="card-row card-row-margin shop-open oneX-body--secondary">
    {{shop?.placeDetails?.open_now}}
  </div>
  <div *ngIf="shop.nationalProvider || shop.certifications" class="card-row card-row-margin banners">
    <div *ngIf="shop.nationalProvider" [style.background]="shop?.unavailable ?  '#FFFFFF' : '#F4F3F3'"
      class="national-provider-container">
      <div class="national-provider-banner oneX-labels">
        Recognized national provider
      </div>
    </div>
    <div *ngIf="shop?.certifications" [style.background]="shop?.unavailable ?  '#FFFFFF' : '#F4F3F3'"
      class="cert-container">
      <div class="cert oneX-labels">
        Vehicle certified
      </div>
    </div>
  </div>
  <div class="-oneX-row button-section-container">
    <!-- Use shop button -->
    <div *ngIf="userIsEligible$ | async"
        class="button-container">
        <button *ngIf="isSecondaryAssignment && (shop.id === this.actorId)"
                id="useShopForClaimButton"
                (click)="promptForUserConfirmation()"
                (keydown.enter)="promptForUserConfirmation()"
                class="-oneX-btn-primary -oneX-btn-medium button">
            Keep shop for claim
        </button>
        <button *ngIf="shop.unavailable && (!isSecondaryAssignment || (shop.id !== this.actorId))"
                id="useShopForClaimButton"
                (click)="promptForUserConfirmation()"
                (keydown.enter)="promptForUserConfirmation()"
                class="-oneX-btn-primary -oneX-btn-medium button">
            Still use this shop
        </button>
        <button *ngIf="!shop.unavailable && (!isSecondaryAssignment || (shop.id !== this.actorId))"
                id="useShopForClaimButton"
                (click)="promptForUserConfirmation()"
                (keydown.enter)="promptForUserConfirmation()"
                class="-oneX-btn-primary -oneX-btn-medium button">
            Use shop for claim
        </button>

    </div>
</div>
</div>
